<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppIcon, { IconName } from "@/Components/Shared/icon/AppIcon.vue";

export type PlaceholderVariant =
    | "background"
    | "transparent"
    | "white"
    | "regular";

type Props = {
    icon?: IconName;
    variant?: PlaceholderVariant;
    hideIcon?: boolean;
};

const {
    icon = "info",
    variant = "regular",
    hideIcon = false,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "text-sm font-medium",
    variants: {
        variant: {
            regular: "p-3 bg-slate-50 rounded-lg text-slate-700",
            background: "p-3 bg-gray-50 text-slate-700",
            white: "p-3 bg-white text-slate-700",
            transparent: "text-slate-400",
        },
    },
};

const attrs = useAttrs();

const { aClass } = installAntlerComponent(
    "placeholder",
    { variant },
    classes,
    attrs,
);
</script>

<template>
    <div :class="aClass()">
        <div
            v-if="icon && !hideIcon"
            class="mr-2 w-6 h-6 basis-6 inline-flex rounded-full text-center items-center justify-center bg-gray-200"
        >
            <AppIcon
                :name="icon"
                size="xsmall"
            />
        </div>

        <slot />
    </div>
</template>
